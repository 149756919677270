<template>
  <div class="wrapper">
    <div class="operate-wrapper">
      <img
        src="@/assets/img/error-oa@2x .png"
        alt
      >
      <p class="error-p">
        {{ '暂未关联企业' }}
      </p>
      <p class="error-span">
        {{ '需关联企业后才可查看相关信息' }}
      </p>
      <cube-button
        class="btn"
        @click="disassociate"
      >
        去关联
      </cube-button>
    </div>
  </div>
</template>

<script>
import { myCustWithBusOppoList } from '_api/global';
export default {
  name: 'AssociatedPage',
  methods: {
    // 跳转
    disassociate() {
      this.$router.push(
        {
          name: 'AssociatedCompanies'
        }
      );
    },
    toAdd() {
      this.$router.push(
        {
          name: 'AddEnterprise'
        }
      );
    },
    getDataList() {
      this.listData = [];
      myCustWithBusOppoList().then(res => {
        if (!res.data.myCustWithBusOppoList.length) {
          this.toAdd();
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: #fff;
}

.operate-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 120px;
    height: 120px;
    margin-top: 152px;
  }
  .error-p {
    margin:16px 0 8px 0;
    color: #23252E;
    font-size: $font-16;
    line-height: 20px;
  }
  .error-span{
    margin-bottom: 32px;
    color: #999BA3;
    font-size: 12px;
    line-height: 16px;

  }
  .btn{
    width: 160px;
    height: 36px;
    line-height: 10px;
  }
}
</style>
